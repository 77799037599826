<footer>
  <div class="div-footer">
    <div class="row row-footer w-100 p-0 m-0"></div>
    <div class="row w-100 p-0 m-0">
      <label class="lbl-footer">{{footer}}</label>
    </div>
    <div class="row w-100 p-0 m-0">
      <img src="assets/home/logo-footer.svg"
           alt="logo-header" class="img-footer">
    </div>
  </div>
</footer>
