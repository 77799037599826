import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'getSafeUrl'
})
export class GetSafeUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  transform(url: string): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://youtube.com/embed/${url}`);
  }

}
