import {Component, OnInit} from '@angular/core';
import Home from '@assets/json/home.json';

@Component({
  selector: 'critertec-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footer: string;

  constructor() {
  }

  ngOnInit(): void {
    this.initValues();
  }

  initValues(): void {
    this.footer = Home.footer;
  }
}
