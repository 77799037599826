import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedRoutingModule} from '@shared/shared-routing.module';
import {MaterialModule} from '@core/material/material.module';
import {GetSafeUrlPipe} from '@shared/pipes/get-safe-url.pipe';


@NgModule({
  declarations: [GetSafeUrlPipe],
  exports: [
    GetSafeUrlPipe
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule
  ]
})
export class SharedModule {
}
