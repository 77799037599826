import {Component, Input, OnInit} from '@angular/core';
import {Breadcrumb} from '@shared/interfaces/breadcrumb';

@Component({
  selector: 'critertec-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadcrumb: Breadcrumb[];

  constructor() {
  }

  ngOnInit(): void {
  }

}
